.contactWrapper {
  width: 100vw;
  height: 100vh;
  background-color: var(--backgroundColor);
  /* background-color: orange; */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 250;
}
.contact {
  width: 100%;
  height: 100%;
  /* background-color: #171f2dad; */
  display: flex;
  /* flex-direction: column; */
  justify-content: space-between;
  align-items: center;
  scroll-padding-top: 5vh;
  /* padding: 2em 5vw; */
  position: relative;
  z-index: 300;
}
.base {
  width: 60%;
  height: 100%;
  /* background-color: var(--light); */
  /* border: 1px solid white; */
  padding: 0vw 7vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.3s cubic-bezier(0.77, 0, 0.175, 1);
}
.contactHeading {
  width: 100%;
  height: 12vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
  /* background-color: #35373d; */
}
.contactHeading p {
  font-size: 2.2vw;
  font-family: 'Epilogue', sans-serif;
  font-weight: 200;
  letter-spacing: 1px;
}
.contactForm {
  width: 100%;
  padding: 10px 0vw;
}
.nameEmail {
  display: flex;
  width: 100%;
  flex-direction: column;
  /* padding-right: 10px; */
}

.nameEmail input {
  width: 100%;
  height: 60px;
  background: var(--additionalColor);
  color: #ffffff;
  font-size: 16px;
  border: none;
  padding: 0px 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.nameEmail input:focus {
  border: 1px solid white;
}

.message textarea {
  width: 100%;
  height: 110px;
  border: none;
  color: #ffffff;
  font-size: 16px;
  background: var(--additionalColor);
  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}

.message textarea:focus {
  border: 1px solid white;
}

.textFeilds {
  display: flex;
  flex-direction: column;
}


.send {
  /* position: absolute; */
  background-color: var(--accentColor);
  /* background-color: red; */
  /* transform: translate(0, 7.4em); */
  width: 100%;
  height: 50px;
  border: none;
  color: white;
  padding: 0px 20px;
  border-radius: 5px;
  transition: 0.3s;
  font-family: 'Epilogue', sans-serif;
  font-weight: 400;
}
.result {
  margin-top: 65px;
  margin-left: 0;
  animation: send 1s linear;
  color: #ffffff;
}
.sendPara {
  transform: translate(-94px, 5px);
  margin: 0;
  padding: 0;
}

.openButton {
  width: 20%;
}
.otherDetails {
  width: 40%;
  height: 100%;
  /* border: 1px solid blue; */
  background-color: var(--additionalColor);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 0px 8vw;
  position: relative;
}
.vysion img {
  width: 50%;
  transform: translate(-20px , -20px);
}
.visit b,
.emailus b,
.followUsOn b{
  font-family: 'Epilogue', sans-serif;
  font-weight: 500;
  font-size: 22px;
  padding: 0 !important;
  margin: 0 !important;
}
.visit,
.emailus,
.followUsOn {
  font-family: "Raleway", sans-serif !important;
  font-weight: 500;
  margin-bottom: 2em;
}
.visit p,
.emailus p{
  padding: 0 !important;
  margin: 0 !important;
  font-size: 18px;
}

.followUsOn div a i{
  margin-right: 24px; 
}
#insta:hover {
  color: #ff41ad;
}
#linkedin:hover {
  color: #0e76a8;
}
#twitter:hover {
  color: #00acee;
}
#facebook:hover {
  color: #4267b2;
}

.card_decoration {
  position: absolute;
  top: 0;
  right: 50%;
  z-index: 300;
  width: 100%;
  overflow: hidden;
  transform: scale(2);
  display: flex;
  justify-content: flex-end;
  transition: 0.5s cubic-bezier(0, 0.7, 0.23, 0.98);
}

.closeContactButton{
  position: absolute;
  top: 0;
  right: 41vw;
  border: none;
  z-index: 320;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  transform: rotate(45deg);
}
.closeWrapper{
  display: none;
   /* background-color: var(--additionalColor); */
   width: 100%;
   height: 46px;
   /* border: none; */
   color: white;
   padding: 0px 20px;
   border-radius: 5px;
   background-color: transparent;
   border: 0.01vh solid white ;
   transition: 0.3s;
   font-family: 'Epilogue', sans-serif;
}

@media screen and (max-width: 800px) {
  .base{
    width: 100%;
    justify-content: flex-start;
    padding-top: 50px;
  }
  .otherDetails{
    display: none;
  }
  .contactHeading p {
    font-size: 5vw;
  }
  .nameEmail input {
    font-size: 14px;
  }
  .message textarea {
    font-size: 14px;
  }
  .nameEmail,
  .message {
    width: 100%;
  }
  .nameEmail {
    /* margin-bottom: 10px; */
    padding-right: 0px;
  }
  .textFeilds {
    display: flex;
    flex-direction: column;
  }
  .send{
    margin-bottom: 20px;
  }
  .closeWrapper{
    display: block;
  }
  
}
@media screen and (max-width: 500px) {
  .contactHeading p {
    font-size: 6vw;
  }
}
