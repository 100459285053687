.yuvaan {
  width: 100%;
  height: 100vh;
  /* background-color: black; */
}
.backbhai{
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 0px 5vw;
  position: relative;
  flex-direction: row-reverse;
  /* background: linear-gradient(
    180deg,
    #10401f 0%,
    #0b2914 58.85%,
    rgba(11, 41, 20, 0) 100%,
    #010302 100%
    ); */
    z-index: 100;
}

.descriptionWrapper {
  width: 100%;
  height: fit-content;
  padding: 22px;
  backdrop-filter: blur(8px);
  background: rgba(90, 170, 129, 0.1);
  margin-bottom: 1em;
}
.animationWrapper {
  width: 70%;
  height: 100%;
  /* background-color: #2ead6e; */
  display: flex;
  align-items: center;
  text-align: center;
}
.center{
  width: fit-content;
  height: fit-content;
  /* background-color: #0b2914; */
}
.yuvaanFeatures {
  width: 24%;
  transform: translate( 9% , -6%);
}
.yuvaanDevice {
  width: 59%;
  transform: translate(-8%, -5%);
}
.mountains {
  width: 100%;
  opacity: 0.7;
  position: absolute;
  bottom: 0%;
  left: 0;
}

@media screen and (max-width:800px) {
  .yuvaan{
    flex-direction: column-reverse;
    overflow: hidden;
  }
  .yuvaanFeatures{ display: none; }
  .yuvaanDevice{
    transform: translate(50%, 0%);
  }
  .animationWrapper{ 
    width: 100%; 
    height: fit-content;
    /* background-color: red; */
    padding-right: 0em;
  }
  .animationWrapper img{ width: 50vh; }
  .deconFeatures{ display: none; }
  .backbhai{
    flex-direction: column-reverse;
  }
}
@media screen and (max-width : 550px) {
  .animationWrapper{ 
    width: 100%; 
    padding-right: 0em;
  }
  .yuvaanDevice{
    transform: translate(0%, -5%);
  }
  .animationWrapper img{ width: 100%; }
}
@media screen and (max-width : 380px){
  .animationWrapper img{ width: 100%; }
  
}
@media screen and (max-height : 500px) and (orientation: portrait) {
  .animationWrapper{ display: none; }
}

