.knowMoreWrapper {
  width: 100vw;
  height: 100vh;
  background-color: var(--backgroundColor);
  /* background-color: #24543300; */
  position: fixed;
  top: 0;
  left: 0;
  z-index: 400;
  padding: 20px 7vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.AboutCompany {
  width: 100%;
  height: 40%;
  background-color: var(--additionalColor);
  margin-bottom: 2vw;
}
.below {
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: space-between;
}
.ourServices,
.OurteamAbout {
  width: 49%;
  height: 100%;
  background-color: var(--additionalColor);
}
.AboutCompany,
.ourServices,
.OurteamAbout {
  padding: 4vw 4vw;
  border-radius: 0.8em;
  position: relative;
}
.AboutCompany h3,
.ourServices h3,
.OurteamAbout h3 {
  font-family: 'Epilogue', sans-serif;
  font-weight: 500;
}
.AboutCompany p,
.ourServices p,
.OurteamAbout p {
  font-family: "Raleway", sans-serif;
}

.scale {
  transform-origin: 100% 0%;
  transform: scale(1.3);
}

.closeContactButton {
  position: absolute;
  top: 0vh;
  right: 0vw;
  border: none;
  z-index: 320;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  transform: rotate(45deg);
}

@media (max-width: 768px) {
  .knowMoreWrapper {
    overflow-y: scroll;
    padding: 200px 7vw;
  }
  .AboutCompany,
  .ourServices,
  .OurteamAbout {
    height: fit-content;
    padding: 8vw;
  }
  .ourServices,
  .OurteamAbout {
    width: 100%;
    margin-bottom: 2vw;
  }
  .below {
    flex-direction: column;
  }
  .scale {
    transform-origin: 100% 0%;
    transform: scale(0.7);
  }
}
