.container {
  list-style-type: none;
  height: 100px;
  margin: 0;
  overflow: hidden;
}
.logo {
  width: 20%;
  background-color: blue;
}
.logo img {
  display: block;
  width: 8em;
}

.container li {
  font-family: "Epilogue", sans-serif;
  font-style: normal;
}

.navbarBase {
  /* background-color: #ffffff; */
  /* background-color: #242424ea; */
  width: 100%;
  height: 50px;
  position: fixed;
  top: 0;
  z-index: 120;
  transition: 0.3s;
  /* border-bottom: 1px solid var(--accentColor); */
  padding: 0px 5vw;
  /* filter: blur(2px); */

  backdrop-filter: blur(3px);
}

.navbarWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  /* margin: 0px 10px; */
  /* border: 1px solid red; */
  padding: 0;
}

.linkContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.companylogocontainer {
  width: 30%;
  height: 100%;
  /* background-color: red; */
  display: flex;
  align-items: center;
  /* border: 1px solid red; */
  position: absolute;
  top: 0;
  left: 7vw;
  cursor: pointer;
}
.companylogocontainer img {
  width: 6em;
}
#logoDesktop {
  display: block;
}
#logoMobile {
  display: none;
}

.websiteLinks {
  font-family: "Epilogue", sans-serif;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  color: white;
  font-weight: 200;
  /* background-color: #0d0d0f; */
}

a {
  color: inherit;
}

.hamburgerMenu {
  display: none;
}

.bars{
  width: 20px;
  height: 20px;
  padding: 2px;
  margin-right: 2em;
}
.bar{
  width: 100%;
  height: 2px;
  margin: 3px 0px;
  background-color: white;
}

@media (max-width: 1000px) {
  .navbarWrapper {
    justify-content: flex-end;
  }
  .companylogocontainer img {
    width: 30px;
  }
  #logoDesktop {
    display: none;
  }
  #logoMobile {
    display: block;
  }

  /* navbar hamburgermenu */
  .hamburgerMenu {
    display: block;
    /* margin-right: 6vw; */
    /* background-color: #0C9136; */
  }
  .hamburgerMenu img {
    background-color: black;
  }

  #ham {
    color: white;
  }

  .websiteLinks {
    display: none;
  }

  .hamMenuItems {
    width: 100vw;
    height: 100vh;
    background-color: var(--backgroundColor);

    position: fixed;
    top: 0;
    left: 0;
    z-index: 50;
    transition: 0.3s;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    animation: menu 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  .hamMenuItems div {
    height: 40px;
    width: 40px;
    border: 1px solid white;
    display: flex;
    border-radius: 100px;
    justify-content: center;
    align-items: center;
    margin-bottom: 2em;
  }

  @keyframes menu {
    0% {
      left: 100vw;
    }
    100% {
      left: 0vw;
    }
  }

  .hamMenuItems i {
    color: white;
    margin: 5vh;
  }
}
