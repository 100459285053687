.gurucamp {
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  padding: 0px 5vw;
  overflow: hidden;
  z-index: 100;
}
/* .animationWrapper,.detailWrapper{ 
    border: 1px solid red;
    padding-top: 10vh;
 } */
.descriptionWrapper {
  width: 100%;
  height: fit-content;
  padding: 22px;
  backdrop-filter: blur(8px);
  background: rgba(91, 91, 91, 0.15);
  margin-bottom: 1em;
}
.animationWrapper {
  width: 70%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* background-color: yellowgreen; */
}
.guruDevice {
  width: 38%;
}
.guruFeatures {
  width: 25%;
}
.guru {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
@media screen and (max-width: 800px) {
  .animationWrapper{
    width: 100%;
    justify-content: center;
  }
  .gurucamp{
    flex-direction: column-reverse;
  }
  .guruFeatures{
    display: none;
  }
  .guruDevice{
    width: 50%;
  }
}
@media screen and (max-width: 550px) {
  .guruDevice{
    width: 70%;
  }
}
@media screen and (max-height : 500px) and (orientation: portrait) {
  .animationWrapper{ display: none; }
}
