.decon {
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  padding: 0px 5vw;
  /* background: linear-gradient(180deg, #061021 0%, rgba(0, 0, 0, 0) 74.8%); */
  /* background-color: #061021; */
  background: linear-gradient(180deg, #061021 0%, rgba(6, 16, 33, 0.78) 49.09%, rgba(0, 0, 0, 0) 74.8%);
  z-index: 100;
}
.animationWrapper,
.detailWrapper {
  /* border: 1px solid red; */
  padding-top: 10vh;
}
.descriptionWrapper {
  width: 100%;
  height: fit-content;
  padding: 22px;
  /* background-color: var(--additionalColor); */
  backdrop-filter: blur(8px);
  background: rgba(21, 88, 129, 0.15);
  margin-bottom: 1em;
  z-index: 300;
}
.animationWrapper {
  width: 70%;
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  padding-right: 5em;
  /* background-color: #698ac4; */
}
.animationWrapper img {
  width: 70%;
}
.deconDevice {
  margin-bottom: 4em;
}
.waves {
  width: 100%;
  opacity: 0.7;
  position: absolute;
  bottom: -4%;
  left: 0;
  z-index: 240;
}

@media screen and (max-width:800px) {
  .decon{
    flex-direction: column-reverse;
    overflow: hidden;
  }
  .animationWrapper{ 
    width: 100%; 
    padding-right: 10em;
  }
  .animationWrapper img{ width: 70%; }
  .deconFeatures{ display: none; }
  .deconDevice{ margin-bottom: 0; }
}
@media screen and (max-width : 550px) {
  .animationWrapper{ 
    width: 100%; 
    padding-right: 3em;
  }
  .animationWrapper img{ width: 90%; }
  .deconDevice{ margin-bottom: 0; }
}
@media screen and (max-height : 500px) and (orientation: portrait) {
  .animationWrapper{
    display: none;
  }
}
