.footer {
  width: 100%;
  height: fit-content;
  position: relative;
  background: linear-gradient(180deg, #061021 0%, rgba(6, 16, 33, 0.78) 30.09%, rgba(0, 0, 0, 0) 74.8%);
  z-index: 100;
}
.br{
  display: none !important;
}

.upperFooter {
  display: flex;
  color: white;
  justify-content: space-between;
  padding: 2% 0;
  /* border-bottom: 1px solid var(--accentColor);  */
  border-bottom: 1px solid transparent;
  border-image: linear-gradient(
    to right,
    rgba(0, 0, 0, 0),
    rgba(255, 255, 255, 0.678),
    rgba(0, 0, 0, 0)
  );
  border-image-slice: 1;
}

.upperFooter p {
  margin: 6px;
  font-size: 1vw;
  font-family: "Raleway", sans-serif;
  font-weight: 200;
}
.vysion,
.explore,
.visit,
.emailus,
.legal {
  /* border: 1px solid red; */
  width: 18%;
  padding: 1em;
}
.vysion {
  width: 28%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  /* border: 1px solid red; */
}
.vysion img {
  width: 80%;
}
.legal {
  width: 10%;
}
.explore a{
  cursor: pointer;
}

.lowerFooter {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  /* background-color: #0C9136; */
}
.socials {
  color: white;
  /* background-color: #1e1e5e; */
  margin: 1em 0px;
}

.socials i {
  margin: 0 1em;
}

#insta:hover {
  color: #ff41ad;
}
#linkedin:hover {
  color: #0e76a8;
}
#twitter:hover {
  color: #00acee;
}
#facebook:hover {
  color: #4267b2;
}
a {
  text-decoration: none;
}
a:hover {
  color: inherit;
}
.explore p:hover {
  text-decoration: underline;
}

@media (min-width : 801px){
  .br{
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .vysion {
    display: none;
  }
  .vysion,
  .explore,
  .visit,
  .emailus,
  .legal {
    width: 25%;
  }
  .legal {
    width: 15%;
  }
  .upperFooter {
    padding: 0px 10px;
  }
  .upperFooter p {
    font-size: 12px;
  }
  .visit {
    width: 35%;
  }
  br {
    display: block;
  }
  .footer {
    padding: 0;
  }
}

@media screen and (max-width: 550px) {
  .vysion,
  .explore,
  .visit,
  .emailus,
  .legal {
    width: 50%;
  }
  .upperFooter {
    flex-wrap: wrap;
  }
  br {
    display: none;
  }
}
