
.container {
  display: flex;
  height: 100vh;
  padding: 0px 5vw;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
}
.vision {
  width: 24%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
  text-align: center;
  font-family: 'Epilogue', sans-serif;
  padding: 0px 1em;
  border-right: 2px solid transparent;
  border-image: linear-gradient(
    0deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 49.79%,
    rgba(255, 255, 255, 0) 100%
  );
  border-image-slice: 1;
}
.vision:nth-child(3) {
  border: none;
}
.microAnimations {
  width: 30%;
  /* height: 100px; */
}

.light {
  font-weight: 300 !important;
}
.vision p {
  line-height: 160% !important;
}

/* .about h1 , .vision h1 {
  padding-bottom: 0.3em;
  border-bottom: 3px solid transparent;
  border-image: linear-gradient(to right, var(--accentColor), rgba(0, 0, 0, 0));
  border-image-slice: 1;
} */

@media screen and (max-width: 800px) {
  .vision {
    width: 50%;
  }
  .microAnimations{
    width: 30%;
  }
}

@media screen and (max-width: 750px) {
  .container {
    flex-direction: column;
    justify-content: space-evenly;
  }
  .vision{
    border: none;
    width: 70%;
  }
  .microAnimations{
    width: 20%;
  }
}

@media (max-width: 750px) and (max-height: 600px) and (orientation : landscape){
  .microAnimations{
    display: none;
  }
}

@media (max-width: 550px) and (orientation : portrait){
  .vision{
    width: 80%;
  }
}