.sbmg-delete-container {
    max-width: 600px;
    margin: 3rem auto;
    padding: 2rem;
    min-height: calc(100vh - 200px); /* Adjust based on your header/footer */
    display: flex;
    align-items: center;
    justify-content: center;
}

.sbmg-delete-content {
    /* background-color: red; */
    padding: 2.5rem;
    border-radius: 12px;    
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); */
    width: 100%;
}

.sbmg-delete-title {
    color: #1a237e;
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 2rem;
    font-weight: 600;
    letter-spacing: -0.5px;
}

.sbmg-delete-description {
    color: #546e7a;
    text-align: center;
    margin-bottom: 2.5rem;
    line-height: 1.6;
    font-size: 1.1rem;
    padding: 0 1rem;
}

.sbmg-delete-form {
    max-width: 400px;
    margin: 0 auto;
}

.sbmg-delete-form-group {
    margin-bottom: 2rem;
}

.sbmg-delete-label {
    display: block;
    margin-bottom: 0.75rem;
    color: #37474f;
    font-weight: 500;
    font-size: 1.1rem;
}

.sbmg-delete-input {
    width: 100%;
    padding: 1rem;
    border: 2px solid #e0e0e0;
    border-radius: 8px;
    font-size: 1.1rem;
    transition: all 0.3s ease;
    background-color: #f5f5f5;
}

.sbmg-delete-input:focus {
    outline: none;
    border-color: #1a237e;
    background-color: white;
    box-shadow: 0 0 0 4px rgba(26, 35, 126, 0.1);
}

.sbmg-delete-input::placeholder {
    color: #9e9e9e;
}

.sbmg-delete-button {
    width: 100%;
    padding: 1rem;
    background-color: #d32f2f;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1.1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.sbmg-delete-button:hover {
    background-color: #b71c1c;
    transform: translateY(-1px);
    box-shadow: 0 4px 6px rgba(211, 47, 47, 0.2);
}

.sbmg-delete-button:active {
    transform: translateY(0);
    box-shadow: none;
}

.sbmg-delete-button:disabled {
    background-color: #bdbdbd;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
}

.sbmg-delete-error {
    color: #d32f2f;
    margin: 1rem 0;
    text-align: center;
    font-size: 0.95rem;
    background-color: #ffebee;
    padding: 0.75rem;
    border-radius: 6px;
    border: 1px solid #ffcdd2;
}

.sbmg-delete-success {
    text-align: center;
    color: #2e7d32;
    padding: 2rem;
    background-color: #e8f5e9;
    border-radius: 8px;
    margin-top: 1rem;
    border: 1px solid #c8e6c9;
}

.sbmg-delete-success p {
    font-size: 1.1rem;
    line-height: 1.6;
    margin: 0;
}

/* Animation for success message */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.sbmg-delete-success {
    animation: fadeIn 0.5s ease-out;
}

/* Loading state styles */
.sbmg-delete-button:disabled {
    position: relative;
    background-color: #9e9e9e;
}

@media (max-width: 768px) {
    .sbmg-delete-container {
        padding: 1rem;
        margin: 1rem auto;
    }

    .sbmg-delete-content {
        padding: 1.5rem;
    }

    .sbmg-delete-title {
        font-size: 1.75rem;
    }

    .sbmg-delete-description {
        font-size: 1rem;
        padding: 0;
    }

    .sbmg-delete-input,
    .sbmg-delete-button {
        font-size: 1rem;
        padding: 0.875rem;
    }
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
    .sbmg-delete-content {
        /* background-color: #1a1a1a; */
        /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2); */
        border: 1px solid gray;
    }

    .sbmg-delete-title {
        color: #e0e0e0;
    }

    .sbmg-delete-description {
        color: #bdbdbd;
    }

    .sbmg-delete-label {
        color: #e0e0e0;
    }

    .sbmg-delete-input {
        background-color: #2d2d2d;
        border-color: #404040;
        color: #e0e0e0;
    }

    .sbmg-delete-input:focus {
        background-color: #333333;
        border-color: #1976d2;
    }

    .sbmg-delete-input::placeholder {
        color: #757575;
    }

    .sbmg-delete-success {
        background-color: #1b5e20;
        color: #ffffff;
        border-color: #2e7d32;
    }

    .sbmg-delete-error {
        background-color: #311b1b;
        border-color: #c62828;
        color: #ef5350;
    }
} 