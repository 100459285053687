/* team container styling */
@import url("http://fonts.cdnfonts.com/css/clash-display");
.teamWrapper {
  width: 100vw;
  height: 100vh;
  background-color: var(--backgroundColor);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 400;
  /* padding: 5vw 5vw; */
  box-sizing: border-box;
  overflow-y: scroll;
}
.teamWrapper1,
.teamWrapper2 {
  width: 100vw;
  height: 50vh;
  /* border: 1px solid white; */

  display: flex;
  justify-content: center;
  position: relative !important;
}
.teamWrapper1 {
  align-items: flex-end;
}
.teamWrapper2 {
  align-items: flex-start;
}

.MemberCard {
  margin: 1.7vw;
  width: 14vw;
  min-width: 212px;
  min-height: 250px;
  height: 29vh;
  background: url("https://ik.imagekit.io/hgfugmm0dbi/Vysion_Website_Assets/Rectangle_937_egVS5MixvI.svg?ik-sdk-version=javascript-1.4.3&updatedAt=1658218151189")
    no-repeat;
  background-size: cover;
  border-radius: 1.3em;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  z-index: 600;
}
.profile_photo_wrapper {
  width: 100%;
  height: 45%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  /* border: 1px solid white; */
}
.profile_photo_border {
  border-radius: 200px;
  height: 80px;
  min-width: 70px;
  max-width: 90px;
  width: 80px;
  min-height: 70px;
  max-height: 90px;
  display: flex;
  min-height: 70px;
  min-width: 70px;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  z-index: 400;
}
.profile_photo {
  width: 90%;
  height: 90%;
  background-color: grey;
  border-radius: 200px;
}
.profile_photo img {
  width: 100%;
  border-radius: 200px;
}

.name_role {
  width: 100%;
  height: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
  /* padding: 50px 0px; */
  transform: translateY(20%);
  transition: 0.3s;
}
.name {
  margin: 0 !important;
  padding: 0 !important;
  font-weight: 400;
  font-family: 'Epilogue', sans-serif;
}
.role {
  margin: 0 !important;
  padding: 0 !important;
  font-weight: 200;
  font-family: 'Epilogue', sans-serif;
  font-size: 0.8vw !important;
  transform: translateY(-4px);
  width: 80%;
  text-align: center;
  letter-spacing: 1px;
}

.social_buttons {
  width: 100%;
  height: 20%;
  /* border: 1px solid red; */
  display: flex;
  justify-content: center;
  /* align-items: center; */
  transform: translateY(100%);
  transition: 0.3s;
}
.social_buttons i {
  margin: 0vw 0.4vw 0px 0.4vw;
  transition: 0.3s;
  cursor: pointer;
}
#mem_insta:hover {
  color: #ff41ad;
}
#mem_linkedin:hover {
  color: #0e76a8;
}
#mem_twitter:hover {
  color: #00acee;
}

.MemberCard:hover .social_buttons {
  transform: translateY(0%);
}
.MemberCard:hover .name_role {
  transform: translateY(0%);
}

.MemberCard:hover .card_decoration {
  transform: translateY(-20px);
}

.closeContactButton {
  position: absolute;
  top: 3vh;
  right: 15vw;
  border: none;
  z-index: 320;
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  cursor: pointer;
  transform: rotate(45deg);
}

@media (max-width: 900px) {
  .teamWrapper {
    width: 100%;
    box-sizing: border-box;
    overflow: hidden;
    height: 100vh;
    overflow-y: scroll;
    padding-top: 10vw;
  }
  .teamWrapper1,
  .teamWrapper2 {
    height: fit-content;
    /* border: 1px solid white; */

    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    position: relative !important;
  }
  .MemberCard {
    min-width: 70vw;
    min-height: 250px;
  }
  .role{
    font-size: 1.9vw !important;
  }
  .social_buttons{
    transform: translateY(0%);
  }
  .name_role{
    transform: translateY(0px);
  }
  .social_buttons i {
    margin: 0vw 2.8vw 0px 2.8vw;
  }
  .closeContactButton{
    position: fixed !important;
  }
}

@media (max-width: 500px) {
  .MemberCard{
    min-width: 60vw;
  }
  .role{
    font-size: 3.4vw !important;
  }
}
