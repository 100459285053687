.sbmg-privacy-container {
    margin: 0 auto;
    padding: 2rem;
    background-color: #f8f9fa;
    min-height: 100vh;
}

.sbmg-privacy-content {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.sbmg-privacy-heading {
    color: #2c3e50;
    text-align: center;
    margin-bottom: 1.5rem;
    font-size: 2.2rem;
}

.sbmg-privacy-metadata {
    text-align: center;
    color: #666;
    margin-bottom: 2rem;
}

.sbmg-privacy-section {
    margin-bottom: 2.5rem;
}

.sbmg-privacy-section-title {
    color: #34495e;
    border-bottom: 2px solid #eee;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    font-size: 1.8rem;
}

.sbmg-privacy-subsection-title {
    color: #2c3e50;
    margin: 1.5rem 0 1rem;
    font-size: 1.4rem;
}

.sbmg-privacy-subsection {
    padding-left: 1rem;
}

.sbmg-privacy-list {
    list-style-type: disc;
    padding-left: 2rem;
    margin: 1rem 0;
}

.sbmg-privacy-list-item {
    margin-bottom: 0.5rem;
    line-height: 1.6;
    color: #444;
}

.sbmg-privacy-text {
    line-height: 1.8;
    color: #444;
    margin-bottom: 1rem;
}

.sbmg-privacy-contact {
    background-color: #f8f9fa;
    padding: 1.5rem;
    border-radius: 6px;
    margin-top: 2rem;
}

.sbmg-privacy-contact-details {
    margin-top: 1rem;
}

.sbmg-privacy-contact-details .sbmg-privacy-text {
    margin-bottom: 0.5rem;
}

.sbmg-privacy-footer {
    margin-top: 2rem;
    padding-top: 1rem;
    border-top: 1px solid #eee;
    text-align: center;
    font-style: italic;
}

@media (max-width: 768px) {
    .sbmg-privacy-container {
        padding: 1rem;
    }

    .sbmg-privacy-content {
        padding: 1rem;
    }

    .sbmg-privacy-heading {
        font-size: 1.8rem;
    }

    .sbmg-privacy-section-title {
        font-size: 1.5rem;
    }

    .sbmg-privacy-subsection-title {
        font-size: 1.2rem;
    }
}