/* @import url("http://fonts.cdnfonts.com/css/clash-display"); */
@import url('https://fonts.googleapis.com/css2?family=Epilogue:wght@100;200;400;500;700&display=swap');

.mainBody {
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  padding: 0px 5vw;
}
.contentWrapper {
  width: 100%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 1px solid orange; */
}
.text {
  width: 100%;
  height: 100%;
  /* border: 2px solid red; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.textContents {
  text-align: center;
  margin-top: -7em;
  /* border: 1px solid red; */
}
.text p {
  font-family: 'Epilogue', sans-serif;
  margin-top: 1em !important;
}
.text h1 {
  font-family: 'Epilogue', sans-serif;
  text-transform: uppercase;
}
.text p,
.text h1 {
  margin: 0;
  padding: 0;
  transition: 0.5s;
  transition-timing-function: cubic-bezier(0.01, 0.73, 0.21, 0.99);
}
.particlesStyle {
  width: 40vw;
  height: 40vh;
  position: absolute;
  z-index: 10;
}
.image {
  width: 40%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid red; */
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 200;
}
.image img {
  width: 28vw;
  position: absolute;
}
.img {
  display: none;
  animation: fade 5s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite;
}
.img:nth-child(1){
  width: 20vw !important;
}
.img:nth-child(2){
  width: 28vw !important;
}
.img:nth-child(3){
  width: 19vw !important;
}


@keyframes fade {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0px);
  }
}

.decon {
  transform: translateY(50%);
  padding-left: 2em;
  padding-top: 3em;
}

.photo {
  /* background-color: blue; */
  width: 100%;
  margin: auto;
  vertical-align: middle;
  padding-left: 3rem; /* height: 100%; */
}

.productsWrapper {
  width: 70%;
  height: 100px;
  /* background-color: rgb(43, 36, 27); */
  /* border: 1px solid purple; */

  position: absolute;
  bottom: 0;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.product {
  font-family: 'Epilogue', sans-serif;
  width: 33.3%;
  height: 100%;
  /* background-color: blue; */
  /* border: 1px solid white; */

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 1.8vw;
  font-weight: 500;
  user-select: none;

  position: relative;

  transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.decoration {
  width: 35vw;
  /* height: 80vh; */
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
  /* border: 2px solid white; */
  transition: 0.4s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.back {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 50rem;
  top: -50rem;
  z-index: -1;
  background: #191919;
  border-radius: 144px;
  transition: 0.2s;
}

.scrollicon {
  display: flex;
  justify-content: space-around;
  padding-bottom: 1.5rem;
  margin-top: -1rem;
  visibility: hidden;
}

.icondown {
  background-color: rgb(73, 73, 73) !important;
  padding: 5px;
  clip-path: circle();
}

.onClick {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 102;
  /* border: 1px solid red; */
}
/* .onClick:hover #poss{
  transform: translateX(100px);
} */

.tooltip{
  font-size: 14px;
  font-weight: 300;
  background-color: black;
  padding: 4px 8px;
  /* border: 0.4px solid grey; */
  border-radius: 5px;
  color: white;
  position: absolute;
  transform: translateY(40px);
  z-index: 10;
}
.tooltip::after{
  content: "";
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: black;
  /* border: 0.4px solid grey; */
  transform: rotate(45deg) translate(-60px , 50px);
  z-index: -1;
}

@media (max-width: 800px) {
  .image {
    display: none;
  }
  .productsWrapper {
    display: none;
  }
  .text {
    width: 100%;
    justify-content: center;
  }
}

@media (max-width: 550px) {
  .textContents h1 {
    font-size: 8vw;
  }
  .textContents{
    margin-top: -4em;
    width: 90%;
  }
}

@media (max-width: 300px) {
  h1 {
    font-size: 10vw;
  }
}
