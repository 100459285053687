@import url("http://fonts.cdnfonts.com/css/clash-display");
.team {
  width: 100vw;
  height: 100vh;
  display: flex;
  padding: 0px 10vw;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.about_company_card {
  width: 23vw;
  height: 38vh;
  min-height: 260px;
  min-width: 260px;
  background-color: #121d30d5;
  border-radius: 1em;
  z-index: 100;
  padding: 1.7em;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: 0.5s cubic-bezier(0, 0.7, 0.23, 0.98);
}
.about_company_card:hover .card_decoration {
  /* border: 1px solid var(--accentColor) ; */
  transform: translateY(-20px);
}
.about_company_card:hover .illustration_Container {
  transform: scale(1.01);
}
.cardHeadingLine {
  font-size: 1.3vw;
  font-family: 'Epilogue', sans-serif;
  font-weight: 200;
  /* border: 1px solid red; */
}
.forwardTextStyling {
  font-size: 1.15vw;
  font-family: 'Epilogue', sans-serif;
  width: 70%;
  padding: 0;
  margin: 0;
}
.illustration_Container {
  width: 100%;
  height: 16vh;
  /* border: 1px solid red; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: 0.5s cubic-bezier(0, 0.7, 0.23, 0.98);
  /* transform-origin: 50% 50%; */
}
.illustration_Container img {
  height: 60%;
  min-height: 60px;
}
.access_controls {
  width: 100%;
  height: 5vh;
  /* border: 1px solid red; */
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card_decoration {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 300;
  width: 100%;
  overflow: hidden;
  /* border: 1px solid red; */
  display: flex;
  justify-content: flex-end;
  transition: 0.5s cubic-bezier(0, 0.7, 0.23, 0.98);
}

.growAnimation3 {
  right: 10vw;
  top: 50%;
  transform-origin: 80% 50%;
}
.growAnimation2 {
  right: 50%;
  top: 63%;
  transform-origin: 50% 50%;
}
.growAnimation1 {
  left: 10vw;
  top: 50%;
  transform-origin: 20% 50%;
}
.growAnimation1,
.growAnimation2,
.growAnimation3 {
  border-radius: 1000vh;
  z-index: 220;
  /* border: 0.1px solid white; */
  /* background-color: red; */
  transition: 0.6s linear;
  width: 0.1vw;
  height: 0.1vw;
  position: fixed;
}
.followTheMouse{
 width: 10px;
 height: 10px;
 position: fixed;
 /* background-color: red; */
 z-index: 101;
 /* display: none; */
 transform: translate(10px , 0px);
/*  */
}
.about_company_card:hover .followTheMouse{
  display: block;
  background-color: blue !important;
}

@media (max-width: 1000px){
  .access_controls {
    display: none;
  }
  .about_company_card {
    justify-content: center;
    width: 23vw;
    height: 38vh;
    min-height: 200px;
    min-width: 200px;
  }
  .cardHeadingLine {
    font-size: 1.7vw;
  }
}

@media (max-width: 800px){
  .team{
    flex-direction: column;
    padding: 0px 10vw;
    justify-content: center;
  }
  .access_controls {
    display: none;
  }
  .about_company_card {
    justify-content: center;
    width: 100%;
    height: 22%;
    min-width: 100%;
    /* max-height: 25vh; */
    min-height: 150px;
    margin-bottom: 1em;
  }
  .illustration_Container{
    width: 18%;
  }
  .cardHeadingLine {
    font-size: 4vw;
    margin: 0px 0px 0px 1em;
  }
  .hiddenDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .growAnimation3 {
    right: 50%;
    top: 80%;
    transform-origin: 50% 80%;
  }
  .growAnimation2 {
    right: 50%;
    top: 49%;
    transform-origin: 50% 50%;
  }
  .growAnimation1 {
    left: 50%;
    top: 20%;
    transform-origin: 50% 20%;
  }
  .growAnimation1,
.growAnimation2,
.growAnimation3 {
  border-radius: 1000px;
  z-index: 220;
  /* border: 0.1px solid white; */
  transition: 0.3s linear;
 
}

}

@media (max-width: 600px){
  .cardHeadingLine {
    font-size: 4.6vw;
    margin: 0px 0px 0px 1em;
  }
  .illustration_Container img{
    height: 40%;
    min-height: 40px;
  }
}


